import { Box, Sheet, Skeleton, Table, Typography } from "@mui/joy";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  formatCurrency,
  formatDateWithHours,
  formatNumber,
} from "library-frontend-utils/helpers";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import InfinityScrollButton from "../InfinityScrollButton/InfinityScrollButton";
import FeedbackMessage from "../FeedbackMessage/FeedbackMessage";
import TooltipTruncateText from "../TooltipTruncateText/TooltipTruncateText";
import {
  forwardRef,
  memo,
  useRef,
  useImperativeHandle,
  useMemo,
  useEffect,
} from "react";
import type { Table as TanstackTable } from "@tanstack/react-table";

const translationStrings = [
  "Error",
  "Loading...",
  "Load More...",
  "Activity",
  "Start Time",
  "End Time",
  "DSV Time",
  "Planned Time",
  "Actual Time",
  "\u0394 Days",
  "Planned AFE",
  "Est. VoWD",
  "\u0394 Cost",
  "Baseline Cost Prediction",
  "DSV Cost Prediction",
  "Total",
  "Oops! Something went wrong on our end. Please try again in a few minutes.",
  "Nothing here yet!",
  "No data available to display. Once data is added, it will appear here.",
] as const;

type TableDataType = {
  id: string;
  taskDescription: string;
  taskStartDate: Date;
  taskEndDate: Date;
  dsvTime: number | null;
  plannedTime: number | null;
  actualTime: number | null;
  plannedDaysAheadBehind: number | null;
  plannedAfeCost: number | null;
  vowdEstimationCost: number | null;
  taskCost: number | null;
  baselineCostPrediction: number | null;
  dsvCostPrediction: number | null;
};

type WellboreCostTrackingTableProps = {
  data?: TableDataType[];
  total?:
    | ({ actualCost: number | null } & Omit<
        TableDataType,
        "taskDescription" | "taskStartDate" | "taskEndDate" | "id" | "taskCost"
      >)
    | null;
  currency?: string;
  isLoading?: boolean;
  isTotalLoading?: boolean;
  isError?: boolean;
  isTotalError?: boolean;
  hasNextPage?: boolean;
  isLoadingNextPage?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  onFetchNextPage?: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<{
      results: TableDataType[];
    }>
  >;
};

type TableObserver = (table: TanstackTable<TableDataType>) => void;

type WellboreCostTrackingTableRef = {
  getTable: () => TanstackTable<TableDataType> | undefined;
  onTableUpdate: (callback: TableObserver) => () => void;
};

const columnHelper =
  createColumnHelper<
    NonNullable<WellboreCostTrackingTableProps["data"]>[number]
  >();

const columns = (
  t: NonNullable<WellboreCostTrackingTableProps["translations"]>,
  total: WellboreCostTrackingTableProps["total"],
  currency: WellboreCostTrackingTableProps["currency"]
) => [
  columnHelper.accessor("taskDescription", {
    id: "taskDescription",
    header: t["Activity"],
    footer: t["Total"],
  }),
  columnHelper.accessor("taskStartDate", {
    id: "taskStartDate",
    header: t["Start Time"],
    cell: ({ getValue }) => formatDateWithHours(getValue()),
  }),
  columnHelper.accessor("taskEndDate", {
    id: "taskEndDate",
    header: t["End Time"],
    cell: ({ getValue }) => formatDateWithHours(getValue()),
  }),
  columnHelper.accessor("dsvTime", {
    id: "dsvTime",
    header: t["DSV Time"],
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? formatNumber(value, 2) : "";
    },
    footer: total?.dsvTime ? formatNumber(total.dsvTime, 2) : "",
  }),
  columnHelper.accessor("plannedTime", {
    id: "plannedTime",
    header: t["Planned Time"],
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? formatNumber(value, 2) : "";
    },
    footer: total?.plannedTime ? formatNumber(total.plannedTime, 2) : "",
  }),
  columnHelper.accessor("actualTime", {
    id: "actualTime",
    header: t["Actual Time"],
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? formatNumber(value, 2) : "";
    },
    footer: total?.actualTime ? formatNumber(total.actualTime, 2) : "",
  }),
  columnHelper.accessor("plannedDaysAheadBehind", {
    id: "plannedDaysAheadBehind",
    header: t["\u0394 Days"],
    cell: ({ getValue }) => {
      const value = getValue();
      return value ? formatNumber(value, 2, "always") : "";
    },
    footer: total?.plannedDaysAheadBehind
      ? formatNumber(total.plannedDaysAheadBehind, 2, "always")
      : "",
  }),
  columnHelper.accessor("plannedAfeCost", {
    id: "planneplannedAfeCost",
    header: `${t["Planned AFE"]} (${currency ?? ""})`,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value || !currency) return "";
      return <TooltipTruncateText text={formatCurrency(value, currency)} />;
    },
    footer: () =>
      currency && total?.plannedAfeCost ? (
        <TooltipTruncateText
          text={formatCurrency(total.plannedAfeCost, currency)}
        />
      ) : (
        ""
      ),
  }),
  columnHelper.accessor("vowdEstimationCost", {
    id: "vowdEstimationCost",
    header: `${t["Est. VoWD"]} (${currency ?? ""})`,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value || !currency) return "";
      return <TooltipTruncateText text={formatCurrency(value, currency)} />;
    },
    footer: () =>
      currency && total?.vowdEstimationCost ? (
        <TooltipTruncateText
          text={formatCurrency(total.vowdEstimationCost, currency)}
        />
      ) : (
        ""
      ),
  }),
  columnHelper.accessor("taskCost", {
    id: "taskCost",
    header: `${t["\u0394 Cost"]} (${currency ?? ""})`,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value || !currency) return "";
      return <TooltipTruncateText text={formatCurrency(value, currency)} />;
    },
    footer: () =>
      currency && total?.actualCost ? (
        <TooltipTruncateText
          text={formatCurrency(total.actualCost, currency)}
        />
      ) : (
        ""
      ),
  }),
  columnHelper.accessor("baselineCostPrediction", {
    id: "baselineCostPrediction",
    header: `${t["Baseline Cost Prediction"]} (${currency ?? ""})`,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value || !currency) return "";
      return <TooltipTruncateText text={formatCurrency(value, currency)} />;
    },
    footer: () =>
      total?.baselineCostPrediction
        ? currency && (
            <TooltipTruncateText
              text={formatCurrency(total.baselineCostPrediction, currency)}
            />
          )
        : "",
  }),
  columnHelper.accessor("dsvCostPrediction", {
    id: "dsvCostPrediction",
    header: `${t["DSV Cost Prediction"]} (${currency ?? ""})`,
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value || !currency) return "";
      return <TooltipTruncateText text={formatCurrency(value, currency)} />;
    },
    footer: () =>
      currency && total?.dsvCostPrediction ? (
        <TooltipTruncateText
          text={formatCurrency(total.dsvCostPrediction, currency)}
        />
      ) : (
        ""
      ),
  }),
];

const WellboreCostTrackingTable = forwardRef<
  WellboreCostTrackingTableRef,
  WellboreCostTrackingTableProps
>(
  (
    {
      data = [],
      total,
      currency,
      onFetchNextPage: onTriggerFetchNextPage,
      isError,
      isLoading,
      isTotalLoading,
      isTotalError,
      hasNextPage = false,
      isLoadingNextPage = false,
      translations,
    },
    ref
  ) => {
    const t = useMemo(
      () => ({
        ...defaultTranslations(translationStrings),
        ...translations,
      }),
      [translations]
    );
    const sheetRef = useRef<HTMLTableElement>(null);
    const headerStyle = {
      color: "var(--joy-palette-text-primary)",
      fontWeight: "var(--joy-fontWeight-xl)",
    };
    const observers = useRef<Set<TableObserver>>(new Set());

    const table = useReactTable({
      columns: columns(t, total, currency),
      data,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      defaultColumn: { size: 120 },
    });

    useEffect(() => {
      observers.current.forEach((observer) => {
        observer(table);
      });
    }, [table, data]);

    // Expose the table instance and the ability to subscribe to updates
    useImperativeHandle(
      ref,
      () => ({
        getTable: () => table,
        onTableUpdate: (callback: TableObserver) => {
          observers.current.add(callback);
          return () => {
            observers.current.delete(callback);
          };
        },
      }),
      [table]
    );

    if (isError || (!isLoading && data.length === 0)) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height={572}
        >
          <FeedbackMessage
            {...(isError
              ? {
                  title: t["Error"],
                  description:
                    t[
                      "Oops! Something went wrong on our end. Please try again in a few minutes."
                    ],
                  isError,
                }
              : {
                  title: t["Nothing here yet!"],
                  description:
                    t[
                      "No data available to display. Once data is added, it will appear here."
                    ],
                })}
          />
        </Box>
      );
    }

    return (
      <Sheet
        ref={sheetRef}
        sx={{
          overflowY: "auto",
          background: (theme) => theme.palette.background.surface,
          borderRadius: "md",
          borderTopRightRadius: () =>
            sheetRef.current?.scrollHeight &&
            sheetRef.current.scrollHeight > sheetRef.current.clientHeight
              ? "calc(2*var(--joy-radius-md))"
              : "var(--joy-radius-md)",
          borderBottomRightRadius: () =>
            sheetRef.current?.scrollHeight &&
            sheetRef.current.scrollHeight > sheetRef.current.clientHeight
              ? "calc(2*var(--joy-radius-md))"
              : "var(--joy-radius-md)",
          maxHeight: 572,
        }}
        tabIndex={0}
      >
        <Table
          borderAxis="none"
          stickyFooter
          stickyHeader
          stripe="odd"
          variant="outlined"
          sx={{
            border: 0,
            "--TableCell-cornerRadius": (theme) => theme.radius.md,
            "--TableCell-paddingX": "10px",
            "--TableCell-paddingY": "14px",
            "--TableCell-footBackground": (theme) =>
              theme.palette.background.level2,
            "--TableRow-stripeBackground": (theme) =>
              theme.palette.background.body,
            fontSize: "sm",
            color: "text.primary",
            "& thead > tr": {
              "& > th:first-of-type": {
                borderBottomLeftRadius: "var(--TableCell-cornerRadius)",
              },
              "& > th:last-of-type": {
                borderBottomRightRadius: "var(--TableCell-cornerRadius)",
              },
              "& > th": headerStyle,
            },
            "& tfoot > tr": {
              "& > th:first-of-type": {
                borderTopLeftRadius: "var(--TableCell-cornerRadius)",
                borderBottomRightRadius: 0,
              },
              "& > td:first-of-type": {
                borderBottomLeftRadius: 0,
              },
              "& > td:last-of-type": {
                borderTopRightRadius: "var(--TableCell-cornerRadius)",
              },
              "& > th": headerStyle,
              "& > td": {
                color: "var(--joy-palette-text-primary)",
                fontWeight: "var(--joy-fontWeight-md)",
              },
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    {...(typeof header.column.columnDef.header === "string" && {
                      title: header.column.columnDef.header,
                    })}
                    style={{
                      width: header.getSize(),
                      whiteSpace: "pre-wrap",
                      verticalAlign: "middle",
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading ? (
              Array.from({ length: 20 }).map((_, index) => (
                <tr key={`loading${index.toString()}`}>
                  {table.getAllColumns().map(({ id }) => (
                    <td key={id}>
                      <Typography>
                        <Skeleton
                          sx={{ display: "inline-block", width: "100%" }}
                        >
                          {t["Loading..."]}
                        </Skeleton>
                      </Typography>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <>
                {table.getRowModel().rows.map(({ id, getVisibleCells }) => (
                  <tr key={id}>
                    {getVisibleCells().map(
                      ({
                        id,
                        column: {
                          columnDef: { cell },
                        },
                        getContext,
                      }) => (
                        <td key={id}>{flexRender(cell, getContext())}</td>
                      )
                    )}
                  </tr>
                ))}
                {data.length !== 0 && hasNextPage && (
                  <tr>
                    <td
                      colSpan={table.getHeaderGroups()[0].headers.length}
                      style={{ textAlign: "center" }}
                    >
                      <InfinityScrollButton
                        {...{
                          hasNextPage,
                          onTriggerFetchNextPage,
                          isLoading: isLoadingNextPage,
                          loadMoreText: t["Load More..."],
                        }}
                      />
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map(({ headers: footers, id }) =>
              isTotalLoading ? (
                <tr key={id}>
                  <th>
                    {flexRender(
                      footers[0].column.columnDef.footer,
                      footers[0].getContext()
                    )}
                  </th>
                  {footers.slice(1).map((_, index) => (
                    <td key={`loading-${id}-${index.toString()}`}>
                      <Typography>
                        <Skeleton
                          sx={{ display: "inline-block", width: "100%" }}
                        >
                          {t["Loading..."]}
                        </Skeleton>
                      </Typography>
                    </td>
                  ))}
                </tr>
              ) : isTotalError ? (
                <tr key={`error-${id}`}>
                  <th>
                    {flexRender(
                      footers[0].column.columnDef.footer,
                      footers[0].getContext()
                    )}
                  </th>
                  {footers.slice(1).map((footer, index) => (
                    <td key={footer.id}>{index === 0 && t["Error"]}</td>
                  ))}
                </tr>
              ) : (
                <tr key={`data-${id}`}>
                  <th>
                    {flexRender(
                      footers[0].column.columnDef.footer,
                      footers[0].getContext()
                    )}
                  </th>
                  {footers.slice(1).map((footer) => (
                    <td key={footer.id}>
                      {flexRender(
                        footer.column.columnDef.footer,
                        footer.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              )
            )}
          </tfoot>
        </Table>
      </Sheet>
    );
  }
);

WellboreCostTrackingTable.displayName = "WellboreCostTrackingTable";
export default memo(WellboreCostTrackingTable);
