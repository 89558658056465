import type {} from "@mui/x-date-pickers-pro/themeAugmentation";
import colors from "../tokens/colors";
import breakpoints from "../tokens/breakpoints";
import typography from "../tokens/typography";
import shadow from "../tokens/shadow";
import { createTheme } from "@mui/material/styles";

import {
  buttonCustomizations,
  pickerCustomizations,
  popoverCustomizations,
  treeCustomizations,
} from "./customizations";

const materialTheme = () => {
  return createTheme({
    cssVariables: {
      colorSchemeSelector: ".theme-%s",
    },
    colorSchemes: {
      light: {
        palette: {
          info: {
            light: colors.grey[300],
            main: colors.grey[200],
            dark: colors.grey[500],
            contrastText: colors.grey[800],
          },
          primary: {
            light: colors.blue[200],
            main: colors.blue[500],
            dark: colors.blue[700],
            contrastText: colors.blue[50],
          },
          warning: {
            light: colors.yellow[300],
            main: colors.yellow[500],
            dark: colors.yellow[700],
            contrastText: colors.yellow[800],
          },
          error: {
            light: colors.red[300],
            main: colors.red[500],
            dark: colors.red[700],
            contrastText: colors.red[800],
          },
          success: {
            light: colors.green[300],
            main: colors.green[500],
            dark: colors.green[700],
            contrastText: colors.green[800],
          },
          background: {
            default: colors.grey[100],
            paper: "#FFFFFF",
          },
          text: {
            primary: colors.grey[800],
            secondary: colors.grey[600],
          },
          action: {
            disabledBackground: colors.grey[300],
            disabled: colors.grey[500],
            hoverOpacity: 1,
            selected: colors.grey[800],
            hover: colors.grey[200],
          },
        },
      },
      dark: {
        palette: {
          info: {
            light: colors.grey[600],
            main: colors.grey[800],
            dark: colors.grey[900],
            contrastText: colors.grey[50],
          },
          primary: {
            light: colors.blue[300],
            main: colors.blue[500],
            dark: colors.blue[700],
            contrastText: colors.blue[50],
          },
          warning: {
            light: colors.yellow[400],
            main: colors.yellow[500],
            dark: colors.yellow[700],
            contrastText: colors.yellow[800],
          },
          error: {
            light: colors.red[400],
            main: colors.red[500],
            dark: colors.red[700],
            contrastText: colors.red[800],
          },
          success: {
            light: colors.green[400],
            main: colors.green[500],
            dark: colors.green[700],
            contrastText: colors.green[800],
          },
          background: {
            default: "#182533",
            paper: colors.grey[900],
          },
          text: {
            primary: colors.grey[100],
            secondary: colors.grey[300],
          },
          action: {
            disabledBackground: colors.grey[200],
            disabled: colors.grey[400],
            hoverOpacity: 1,
            selected: colors.grey[100],
            hover: colors.grey[800],
          },
        },
      },
    },
    spacing: 5,
    typography: {
      fontFamily: ['"Inter", "sans-serif"'].join(","),
      h1: {
        letterSpacing: 0,
        fontSize: typography.fontSize.xl4,
        fontWeight: 600,
      },
      h2: {
        letterSpacing: 0,
        fontSize: typography.fontSize.xl3,
        fontWeight: 600,
      },
      h3: {
        letterSpacing: 0,
        fontSize: "1.25rem",
        fontWeight: 600,
      },
      h4: {
        letterSpacing: 0,
        lineHeight: 1.2,
        fontSize: "1.5rem",
        fontWeight: 600,
      },
      fontSize: 12,
    },
    breakpoints: {
      values: breakpoints,
    },
    shadows: [
      "none",
      shadow.xs,
      shadow.sm,
      "none",
      shadow.md,
      "none",
      shadow.lg,
      "none",
      shadow.xl,
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
    ],
    components: {
      ...buttonCustomizations,
      ...pickerCustomizations,
      ...treeCustomizations,
      ...popoverCustomizations,
    },
  });
};

export default materialTheme;
