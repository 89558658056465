import { Tooltip } from "@mui/joy";
import {
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

type TooltipTruncateTextProps = {
  text: string;
};

export default function TooltipTruncateText({
  text,
}: TooltipTruncateTextProps) {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleOverflowing = useCallback(() => {
    const currentTextRef = textRef.current;
    if (!currentTextRef) return;
    if (currentTextRef.scrollWidth > currentTextRef.clientWidth) {
      startTransition(() => {
        setIsOverflowing(true);
      });
    } else {
      startTransition(() => {
        setIsOverflowing(false);
      });
    }
  }, []);

  useEffect(() => {
    handleOverflowing();
    window.addEventListener("resize", handleOverflowing);

    return () => {
      window.removeEventListener("resize", handleOverflowing);
    };
  }, [handleOverflowing]);

  return (
    <Tooltip disableHoverListener={!isOverflowing} title={text}>
      <span
        ref={textRef}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
          maxWidth: "100%",
          verticalAlign: "inherit",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    </Tooltip>
  );
}
