import { type Components, type Theme } from "@mui/joy";

const listCustomizations: Components<Theme> = {
  JoyList: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        "--ListItem-gap": theme.spacing(2),
        '&[marker="disc"]': {
          "--ListItem-paddingX": theme.spacing(0.25),
          "--ListItem-paddingY": theme.spacing(0.25),
        },
        ...(ownerState.size === "sm" && {
          "--ListItem-minHeight": "2rem",
          "--ListItem-paddingY": theme.spacing(0.5),
          "--ListItem-paddingX": theme.spacing(2),
          "--ListItemDecorator-size": "1.5rem",
          "--ListItem-gap": theme.spacing(1),
          "& .MuiListItemButton-root, & .MuiListItem-root": {
            fontSize: theme.fontSize.xs,
            lineHeight: 1,
          },
        }),
        ...(ownerState.size === "md" && {
          "--ListItem-minHeight": "2.5rem",
          "--ListItem-paddingY": theme.spacing(1),
          "--ListItem-paddingX": theme.spacing(3),
          "--ListItemDecorator-size": "2rem",
          "& .MuiListItemButton-root, & .MuiListItem-root": {
            fontSize: theme.fontSize.sm,
            lineHeight: 1.4,
          },
        }),
        ...(ownerState.size === "lg" && {
          "--ListItem-minHeight": "3rem",
          "--ListItem-paddingY": theme.spacing(2),
          "--ListItem-paddingX": theme.spacing(5),
          "--ListItemDecorator-size": "2.5rem",
          "& .MuiListItemButton-root, & .MuiListItem-root": {
            fontSize: theme.fontSize.md,
            lineHeight: 1.2,
          },
        }),
      }),
    },
  },
  JoyListItemButton: {
    styleOverrides: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: "transparent",
        },
      },
    },
  },
};

export default listCustomizations;
