import type { Components, Theme } from "@mui/joy";

const chipCustomizations: Components<Theme> = {
  JoyChip: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        "--Chip-paddingInline": theme.spacing(2),
        ...(ownerState.size === "sm" && {
          "--_Chip-minHeight": "1.25rem",
          "--Chip-decoratorChildHeight": "0.875rem",
          fontSize: theme.vars.fontSize.sm,
        }),
        ...(ownerState.size === "md" && {
          "--_Chip-minHeight": "1.5rem",
          "--Chip-decoratorChildHeight": "1.25rem",
          fontSize: theme.vars.fontSize.md,
          gap: theme.spacing(1),
        }),
        ...(ownerState.size === "lg" && {
          "--_Chip-minHeight": "1.75rem",
          "--Chip-decoratorChildHeight": "1.5rem",
          fontSize: theme.vars.fontSize.lg,
          gap: theme.spacing(1),
        }),
      }),
    },
  },
  JoyChipDelete: {
    styleOverrides: {
      root: {
        backgroundColor: "inherit",
      },
    },
  },
};

export default chipCustomizations;
