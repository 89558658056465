import type { Components, Theme } from "@mui/joy";

const modalCustomizations: Components<Theme> = {
  JoyModalClose: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.radius.xl,
      }),
    },
  },
  JoyDialogTitle: {
    defaultProps: {
      level: "h3",
      sx: { fontWeight: "sm" },
    },
  },
};

export default modalCustomizations;
