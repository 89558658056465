import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/joy";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

type ButtonProps = React.ComponentProps<typeof Button>;
type FeedbackMessageProps = {
  title: string;
  description?: string;
  isError?: boolean;
  buttonProps?: {
    onClick: ButtonProps["onClick"];
    variant?: ButtonProps["variant"];
  };
  buttonLabel?: string;
};

export default function FeedbackMessage({
  title,
  description,
  isError = false,
  buttonProps,
  buttonLabel,
}: FeedbackMessageProps) {
  return (
    <Grid
      container
      direction="column"
      rowSpacing={{ xs: 2, md: 4 }}
      textAlign="center"
      justifyContent="center"
      alignItems="center"
    >
      <Grid>
        <Typography level="h1" {...(isError && { color: "warning" })}>
          <FontAwesomeIcon icon={faExclamationCircle} />
        </Typography>
      </Grid>
      <Grid>
        <Typography level="h1" fontWeight="sm">
          {title}
        </Typography>
      </Grid>
      {description && (
        <Grid>
          <Typography level="body-lg" fontWeight="sm" whiteSpace="pre-wrap">
            {description}
          </Typography>
        </Grid>
      )}
      {buttonProps && buttonLabel && (
        <Grid>
          <Button size="lg" {...buttonProps}>
            {buttonLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
