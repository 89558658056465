import { useCallback, useEffect, useState } from "react";

const _options = {
  threshold: [0.1],
};

export default function useIntersectionObserver(
  callback?: (...args: unknown[]) => unknown,
  options?: IntersectionObserverInit
) {
  const [currentNode, setCurrentNode] = useState<HTMLElement | null>(null);
  const registerRef = useCallback((node: HTMLElement | null) => {
    setCurrentNode(node);
  }, []);

  const intersectionObserverCallback: IntersectionObserverCallback =
    useCallback(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting && callback) {
          callback();
        }
      },
      [callback]
    );

  useEffect(() => {
    const observer = new IntersectionObserver(
      intersectionObserverCallback,
      options ?? _options
    );
    if (!currentNode) return;
    observer.observe(currentNode);

    return () => {
      observer.disconnect();
    };
  }, [currentNode, options, intersectionObserverCallback]);

  return { registerRef };
}
