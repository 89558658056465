import type { Components, Theme } from "@mui/joy";
import colors from "../../tokens/colors";

const tableCustomizations: Components<Theme> = {
  JoyTable: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        "--TableCell-paddingX": theme.spacing(2),
        "--unstable_TableCell-height": "none",
        "--unstable_actionRadius": 0,
        backgroundColor: "transparent",
        color: "var(--joy-palette-text-primary)",
        "tbody tr td:first-child": {
          borderTopLeftRadius: theme.radius.sm,
          borderBottomLeftRadius: theme.radius.sm,
        },
        "tbody tr td:last-child": {
          borderTopRightRadius: theme.radius.sm,
          borderBottomRightRadius: theme.radius.sm,
        },
        borderSpacing: theme.spacing(0, 1),
        "tr td": {
          p: {
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 4,
            textOverflow: "ellipsis",
          },
          button: {
            ...(ownerState.variant === "soft" && {
              backgroundColor: "var(--joy-palette-background-surface)",
            }),
            ...(ownerState.variant === "solid" && {
              backgroundColor: "var(--joy-palette-background-body)",
            }),
            ...((ownerState.variant === "soft" ||
              ownerState.variant === "solid") && {
              color: "var(--joy-palette-text-icon)",
              "&:hover": {
                backgroundColor: "var(--joy-palette-neutral-softHoverBg)",
              },
              "&:active": {
                backgroundColor: "var(--joy-palette-neutral-softActiveBg)",
              },
              "&:disabled": {
                backgroundColor: "var(--joy-palette-neutral-softDisabledBg)",
                color: "var(--joy-palette-text-icon)",
                opacity: 0.6,
              },
            }),
          },
        },
        "tr th": {
          color: "var(--joy-palette-text-primary)",
        },
        "tr.danger td, tr.warning td": {
          button: {
            "&:hover": {
              backgroundColor: colors.alphaWhite[300],
            },
            "&:active": {
              backgroundColor: colors.alphaWhite[300],
            },
            "&:disabled": {
              backgroundColor: colors.alphaWhite[100],
              opacity: 0.6,
            },
          },
        },
        "tr.danger td": {
          background: "var(--joy-palette-danger-solidBg)",
          color: "var(--joy-palette-danger-solidColor)",
          button: {
            backgroundColor: colors.alphaWhite[200],
            color: "var(--joy-palette-neutral-solidBg)",
          },
        },
        "tr.warning td": {
          backgroundColor: "var(--joy-palette-warning-solidBg)",
          color: "var(--joy-palette-warning-solidColor)",
          button: {
            backgroundColor: colors.alphaBlack[50],
            color: "var(--joy-palette-neutral-solidColor)",
          },
        },
        overflow: "auto",
      }),
    },
  },
};

export default tableCustomizations;
