import type { Theme } from "@mui/material";

import type { Components } from "@mui/material/styles";
import type {} from "@mui/x-tree-view/themeAugmentation";

const treeCustomizations: Components<Theme> = {
  MuiTreeItem2: {
    styleOverrides: {
      root: {
        backgroundColor: "transparent",
      },
      content: ({ theme }) => ({
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.info.contrastText,
        },
      }),
    },
  },
};

export default treeCustomizations;
