import { type Components, type Theme } from "@mui/joy";

const tabsCustomizations: Components<Theme> = {
  JoyTabList: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: theme.vars.radius.xl,
        padding: theme.spacing(1),
        gap: theme.spacing(1),
        backgroundColor: theme.vars.palette.background.level1,
        ...(ownerState.size === "sm" && {
          "--ListItem-minHeight": "2rem",
        }),
        ...(ownerState.size === "md" && {
          "--ListItem-minHeight": "2.5rem",
        }),
        ...(ownerState.size === "lg" && {
          "--ListItem-minHeight": "3rem",
        }),
      }),
    },
  },
  JoyTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  JoyTab: {
    defaultProps: {
      disableIndicator: true,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: theme.vars.radius.xl,
        ...(ownerState.color === "neutral" &&
          ownerState.variant === "plain" && {
            "&.Mui-selected": {
              backgroundColor: theme.vars.palette.background.surface,
            },
            "&:not(.Mui-selected):hover": {
              backgroundColor: theme.vars.palette.background.level2,
            },
            "&:not(.Mui-selected):active": {
              backgroundColor: theme.vars.palette.background.surface,
            },
          }),
      }),
    },
  },
  JoyTabs: {
    styleOverrides: {
      root: {
        backgroundColor: "transparent",
      },
    },
  },
};

export default tabsCustomizations;
