import type { Components, Theme } from "@mui/joy";

const switchCustomizations: Components<Theme> = {
  JoySwitch: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        "--Switch-gap": "0.5rem",
        ...(ownerState.size === "sm" && {
          "--Switch-trackHeight": "1.25rem",
          "--Switch-thumbSize": "0.875rem",
          "--Switch-trackWidth": "2.125rem",
        }),
        ...(ownerState.size === "md" && {
          "--Switch-trackHeight": "1.5rem",
          "--Switch-thumbSize": "1.125rem",
          "--Switch-trackWidth": "2.625rem",
        }),
        ...(ownerState.size === "lg" && {
          "--Switch-trackHeight": "2rem",
          "--Switch-thumbSize": "1.625rem",
          "--Switch-trackWidth": "3.5rem",
        }),
      }),
    },
  },
};

export default switchCustomizations;
