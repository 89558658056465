import type { Components, Theme } from "@mui/joy";

export const inputCustomizations: Components<Theme> = {
  JoyInput: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        "--Input-radius": theme.vars.radius.xl,
        "--Input-gap": theme.spacing(ownerState.size === "sm" ? 2 : 3),
        lineHeight: 1,
        boxShadow: "none",
        "&:not([data-skip-inverted-colors])": {
          "--Input-focusedHighlight":
            theme.vars.palette[ownerState.color || "primary"][500],
        },
        ...(ownerState.size === "sm" && {
          "--Input-minHeight": "2rem",
          padding: theme.spacing(1, 2),
        }),
        ...(ownerState.size === "md" && {
          "--Input-minHeight": "2.5rem",
          padding: theme.spacing(2, 3),
        }),
        ...(ownerState.size === "lg" && {
          "--Input-minHeight": "3rem",
          padding: theme.spacing(2, 3),
        }),
        ...(ownerState.variant === "solid" &&
          ownerState.color === "neutral" && {
            boxShadow: theme.vars.shadow.xs,
          }),
        ...(ownerState.variant === "outlined" && {
          backgroundColor: "transparent",
        }),
      }),
      endDecorator: () => ({
        color: "inherit",
      }),
      startDecorator: ({ ownerState }) => ({
        color: "inherit",
        ...(ownerState.size === "sm" && {
          "--Icon-fontSize": "0.875rem",
        }),
        ...(ownerState.size === "md" && {
          "--Icon-fontSize": "1rem",
        }),
        ...(ownerState.size === "lg" && {
          "--Icon-fontSize": "1.25rem",
        }),
      }),
    },
  },
  JoyFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.vars.palette.text.secondary,
        fontSize: theme.vars.fontSize.xs,
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        fontWeight: theme.vars.fontWeight.sm,
      }),
    },
  },
  JoyFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: `var(--FormHelperText-color, ${theme.vars.palette.text.secondary})`,
      }),
    },
  },
};
export default inputCustomizations;
