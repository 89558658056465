import {
  tooltipClasses,
  typographyClasses,
  type Components,
  type Theme,
} from "@mui/joy";

const tooltipCustomizations: Components<Theme> = {
  JoyTooltip: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2),
        borderRadius: theme.radius.sm,
        boxShadow: theme.vars.shadow.sm,
        backgroundColor: theme.vars.palette.neutral.solidBg,
        color: theme.vars.palette.neutral[800],
        [`& .${tooltipClasses.arrow}`]: {
          "&:before": {
            borderColor: theme.vars.palette.neutral.solidBg,
          },
        },
        [`& .${typographyClasses.root}`]: {
          color: "inherit !important",
        },
      }),
    },
  },
};

export default tooltipCustomizations;
