import { useMsal } from "@azure/msal-react";
import { usePostHog } from "posthog-js/react";
import { useEffect, type PropsWithChildren } from "react";
import { getCurrentEnvironment } from "../../infra";

const environment = getCurrentEnvironment();

export default function IdentifyUser({ children }: PropsWithChildren) {
  const { instance } = useMsal();
  const user = instance.getAllAccounts()[0];
  const posthog = usePostHog();

  useEffect(() => {
    if (environment === "local") {
      posthog.opt_out_capturing();
      posthog.set_config({ disable_session_recording: true });
      return;
    }
    posthog.identify(user.idTokenClaims?.oid, {
      name: user.name,
      email: user.username,
    });
  }, [posthog, user.idTokenClaims?.oid, user.name, user.username]);

  return children;
}
