import type { Components, Theme } from "@mui/joy";

const badgeCustomizations: Components<Theme> = {
  JoyBadge: {
    styleOverrides: {
      root: {
        "--Badge-ring": "transparent",
      },
      badge: ({ theme }) => ({
        "font-weight": theme.vars.fontWeight.xl,
      }),
    },
  },
};

export default badgeCustomizations;
