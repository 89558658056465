import type { Components, Theme } from "@mui/joy";

const radioCustomizations: Components<Theme> = {
  JoyRadio: {
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        gap: theme.spacing(1),
        ...(ownerState.size === "md" && {
          gap: theme.spacing(2),
        }),
        ...(ownerState.size === "lg" && {
          gap: theme.spacing(2),
        }),
      }),
    },
  },
};

export default radioCustomizations;
