import type { Components, Theme } from "@mui/joy";

const sliderCustomizations: Components<Theme> = {
  JoySlider: {
    styleOverrides: {
      root: () => ({
        "--Slider-markSize": "0px",
        "&.Mui-disabled": {
          "--Slider-thumbColor":
            "var(--joy-palette-neutral-solidDisabledColor)",
          "--Slider-trackBackground":
            "var(--joy-palette-neutral-solidDisabledColor)",
          "--Slider-thumbBackground":
            "var(--joy-palette-neutral-solidDisabledBg)",
        },
        padding: 0,
      }),
      valueLabel: {
        color: "var(--joy-palette-neutral-900)",
      },
      thumb: {
        "&.Mui-focusVisible, &:focus-visible": { outline: "none" },
        "&::before": {
          borderWidth: "4px",
        },
      },
      markLabel: { display: "none" },
    },
  },
};

export default sliderCustomizations;
