import type { TopLevelFormatterParams } from "echarts/types/dist/shared";
import colors from "../../tokens/colors";
import { Grid, List, ListItem, ListItemDecorator, Typography } from "@mui/joy";
import { formatCurrency, formatNumber } from "library-frontend-utils/helpers";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";

const translationStrings = ["Day"] as const;

type TooltipFormatterProps = {
  param: TopLevelFormatterParams;
  currency?: string;
  translations?: TranslationsType<typeof translationStrings>;
};

export default function TooltipFormatter({
  param,
  currency,
  translations,
}: TooltipFormatterProps) {
  const t = { ...defaultTranslations(translationStrings), ...translations };
  if (!Array.isArray(param) || !param[0] || !Array.isArray(param[0].value))
    return "";

  const { list, day } = param.reduce<{
    day: string | null;
    list: {
      cost: string;
      color?: string;
      name?: string;
    }[];
  }>(
    (acc, { value, color, seriesName: name }) => {
      if (!Array.isArray(value)) return acc;
      acc = {
        day: acc.day
          ? acc.day
          : `${t["Day"]} ${formatNumber(Number(value[0]), 2)}`,
        list: [
          ...acc.list,
          {
            cost: currency
              ? formatCurrency(Number(value[1]), currency, "narrowSymbol")
              : "",
            color: color?.toString(),
            name,
          },
        ],
      };
      return acc;
    },
    {
      list: [],
      day: null,
    }
  );

  if (list.length === 0) return "";

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      p={1}
      border={`1px solid ${colors["grey"][300]}`}
      bgcolor="Background"
      borderRadius="xs"
      minWidth={210}
    >
      <Grid>
        <Typography level="body-sm" fontWeight="lg" textColor="text.primary">
          {day}
        </Typography>
        <List
          sx={{
            minWidth: "100%",
            pb: 0,
            "--ListItemDecorator-size": "10px",
            "--ListItem-gap": 0,
            "--ListItem-paddingX": 0,
            "--ListItem-paddingY": "5px",
            "--ListItem-minHeight": "auto",
          }}
        >
          {list.map(({ cost, color, name }, index) => (
            <ListItem key={`${cost}${index.toString()}`}>
              <ListItemDecorator>
                <span
                  style={{
                    fontWeight: 300,
                    display: "inline-block",
                    width: 12,
                    height: 2,
                    backgroundColor: color,
                    borderRadius: 16,
                  }}
                />
              </ListItemDecorator>
              <Typography
                level="title-sm"
                fontWeight="sm"
                sx={{ flexGrow: 1, mx: 2 }}
              >
                {name}
              </Typography>
              <ListItemDecorator>
                <Typography level="title-sm">{cost}</Typography>
              </ListItemDecorator>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
